import { selectorFamily } from "recoil";
import { globalSearchQueryState, timeDomainDuplicateState } from "../atoms/globalAtoms";
import { aavegService } from "../axios";

export const globalSearchResultsState = selectorFamily({
    key: "globalSearchResultsState",
    get: ({id, type}) => async ({get}) => {
        try {
            const query = get(globalSearchQueryState(id));
            if (query && query?.length >= 2) {
                const res = await aavegService.get(`/search/${type}`, {
                    params: {
                        q: query
                    }
                });
                return res.data;
            }
            return [];
        } catch (error) {
            console.log(error);
            return [];
        }
    }
});

function decomposeDate(date=new Date()) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return { year, month, day, hours, minutes };
}


export const decomposedDomainState = selectorFamily({
    key: "decomposedDomainState",
    get: ({id, start}) => ({get}) => {
        if (start) {
            const { startDate } = get(timeDomainDuplicateState(id));
            return decomposeDate(startDate);
        } 
        const { endDate } = get(timeDomainDuplicateState(id));
        return decomposeDate(endDate);
    },
    set: ({id, start}) => ({ get, set }, newValue) => {
        let newDate = new Date(newValue.year, newValue.month, newValue.day, newValue.hours, newValue.minutes);
        const { startDate, endDate } = get(timeDomainDuplicateState(id));
        if (start) {
            set(timeDomainDuplicateState(id), { endDate, startDate: newDate });
        } else {
            set(timeDomainDuplicateState(id), { startDate, endDate: newDate });
        }
    }
});