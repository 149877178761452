import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import uuid from 'react-uuid';
import { globalSearchQueryState } from '../atoms/globalAtoms';
import { aavegService } from '../axios';
import Modal from '../components/Modal';
import Loader from '../components/Loader';
import ClientSearch from '../components/ClientSearch';
import Error from '../components/Error';


import { searchObjects } from '../utils';

import closeIcon from '../graphics/close.svg';

async function fetchStations() {
    try {
        const res = await aavegService.get(`/stations/all`);
        return res.data;
    } catch (error) {
        throw error;
    }
}

const StationCard = ({ station, handleClose }) => {
    if (!station || isEmpty(station)) return null;
    const { alias, address, imageUrl, slug } = station;
    return (
        <Link to={`/stations/${slug}`} onClick={() => {
            handleClose();
        }}>
            <div 
            className="general-picker-card">
                <div className="img-container">
                    <img src={imageUrl} alt="" />
                </div>
                <div className="block">
                <h4>{alias}</h4>
                    <p>{address}</p>
                </div>
            </div>
        </Link>
    );
};

const searchId = uuid();

const StationNavigatorMenu = ({ toggleMenu }) => {
    const [stationData, setStationData] = useState([]);
    const history = useHistory();
    const query = useRecoilValue(globalSearchQueryState(searchId));
    const { isError: stationsError, isLoading: stationsLoading } = useQuery(
        "all-stations",
        fetchStations,
        {
            onSuccess: (data) => {
                setStationData(data);
            },
            refetchOnWindowFocus: false
        }
    );
    const handleClose = () => {
        toggleMenu(false);
    };
    const filteredStations = searchObjects(query, stationData, ["name", "alias", "address"]);
    return (  
        <Modal type="custom">
            <div className="general-picker-menu modal">
                <div className="plot-title">
                    <h4>
                        Navigate to a Station
                        {stationsLoading ? (
                            <Loader size="tiny" inline={true} />
                        ) : (null)}
                    </h4>
                    <img src={closeIcon} alt="close" className="icon" onClick={handleClose} />
                </div>
                <div className="controls">
                    <ClientSearch searchId={searchId} placeholder="Search for a station" autoFocus />
                </div>
                <div className="body">
                    {stationsError ? (
                        <Error type="error" text="Could not load station data" />
                    ) : (
                        filteredStations.map(station => (
                            <StationCard 
                            key={uuid()} 
                            station={station} 
                            handleClose={handleClose} />
                        ))
                    )}
                </div>
                <div className="btn-group">
                    <button className="secondary-btn" onClick={handleClose}>Close</button>
                    <button className="action-btn" onClick={() => {
                        history.push("/overview");
                        handleClose();
                    }}>Go to all stations</button>
                </div>
            </div>
        </Modal>
    );
};

const StationNavigator = ({ showMenu, toggleMenu, disabled=false, selectedStations, setSelectedStations }) => {
    if (disabled || !showMenu) return null;
    return (
        <StationNavigatorMenu 
        toggleMenu={toggleMenu} 
        selectedStations={selectedStations} 
        setSelectedStations={setSelectedStations} />
    );
}
 
export default StationNavigator;