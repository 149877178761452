import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import uuid from 'react-uuid';

export const Pop = ({id, message, togglePopup, interactive=false}) => {
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });
    const [promptStyle, setPromptStyle] = useState({});
    const [orientation, setOrientation] = useState(0);

    useEffect(() => {
        const child = document.getElementById(id);
        if (child) {
            const bBox = child.getBoundingClientRect();
            let x = bBox.left + bBox.width / 2;
            let y = bBox.top - 10;
            const promptBody = document.getElementById(id + "-prompt");
            let promptBodyStyle = {};
            if (promptBody) {
                let promptRect = promptBody.getBoundingClientRect();
                let dx = 0;
                if (x + (promptRect.width / 2) > window.innerWidth) {
                    let delta = x + (promptRect.width / 2) - window.innerWidth;
                    x = x - delta - 5;
                    dx = 10 + delta;
                } else if (x - (promptRect.width / 2) < 0) {
                    let delta = x - (promptRect.width / 2);
                    x = x + delta + 5;
                    dx = -10 - delta;
                }

                if (y - promptRect.height < 0) {
                    dx = -dx;
                    setOrientation(1);
                }
                promptBodyStyle.transform = `translateX(${dx}px)`
            }
            setPosition({x, y});
            setPromptStyle(promptBodyStyle);
        }
    }, [id]);

    if (!message) return null;
    
    return createPortal(
        <div className="prompt" style={{
            position: "absolute",
            transformOrigin: "50% 100%",
            left: position.x,
            top: position.y,
            transform: `translate(-50%, ${orientation > 0 ? "-50%" : "-100%"}) ${orientation > 0 ? "rotate(180deg)" : ""}`
        }}
        tabIndex={-1}
        onBlur={() => {
            togglePopup(false);
        }}>
            <div 
            className="prompt-body" 
            id={id + "-prompt"} 
            onMouseOver={() => {
                if (!interactive) togglePopup(false);
            }}
            style={{
                transform: orientation > 0 ? "rotate(180deg)" : ""
            }}>{message}</div>
            <div className="prompt-tip"
            style={{
                ...promptStyle
            }}></div>
        </div>,
        document.body
    );
};

const Help = ({children, message, disabled=false, interactive=false, ...rest}) => {
    const ID = uuid();
    const [showPopup, togglePopup] = useState(false);
    return (
        <div 
        id={ID}
        className="utility"
        {...rest}
        onMouseEnter={() => {
            if (!showPopup) togglePopup(true);
        }}
        onMouseLeave={() => {
            togglePopup(false);
        }}>
            {children}
            {!disabled && showPopup ? <Pop id={ID} message={message} togglePopup={togglePopup} interactive={interactive} /> : null}
        </div>
    );
};
 
export default Help;