import React, { useState } from 'react';
import sampleImage from '../graphics/user.png';
import chevron from '../graphics/chevron.svg';
import notifIcon from '../graphics/notif.svg';
import profileIcon from '../graphics/profile.svg';
import settingsIcon from '../graphics/settings.svg';
import orgIcon from '../graphics/organization.svg';
import logoutIcon from '../graphics/switch.svg';
import uuid from 'react-uuid';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { generateRandomHexCode, generateRandomNumber } from '../utils';
import { Suspense } from 'react';
import userIcon from '../graphics/user.png';
import { onlineStatusState, userState } from '../atoms/globalAtoms';
import StatusIndicator from './StatusIndicator';
import useInterval from '../hooks/useInterval';
import { useQuery } from 'react-query';
import { aavegService } from '../axios';


const menuItems = [
    {
        name: "profile",
        icon: profileIcon,
        to: {
            pathname: "/profile"
        }
    },
    {
        name: "organization",
        icon: orgIcon,
        to: {
            pathname: "/organization"
        }
    },
    {
        name: "notifications",
        icon: notifIcon,
        to: {
            pathname: "/notifications"
        }
    },
    {
        name: "settings",
        icon: settingsIcon,
        to: {
            pathname: "/settings"
        }
    },
    {
        name: "logout",
        icon: logoutIcon,
        to: {
            pathname: "/login",
            state: {
                logout: true
            }
        }
    }
];

const offsetTop = 50;

export const DefaultAvatar = ({firstName="", lastName="", id=1}) => {
    if (!firstName || !lastName) return <img src={sampleImage} alt="user" />;
    let initials = firstName.charAt(0) + lastName.charAt(0);
    let hex = "#" + generateRandomHexCode(id);
    return (
        <div className="default-avatar"
        style={{
            backgroundColor: hex
        }}>
            <p>{initials}</p>
        </div>
    );
};

const FallbackAvatar = () => {
    return (
        <div className="small-roundel">
            <img src={userIcon} alt="avatar" />
        </div>
    );
}

function fetchUserProfile(userId) {
    return async function() {
        if (!userId) return null;
        try {
            const res = await aavegService.get(`/users/${userId}`);
            return res.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
}

const UserAvatar = () => {
    const { user: userId } = useRecoilValue(userState);
    const [userProfile, setUserProfile] = useState(null);
    const { isLoading, isError } = useQuery(
        "user-profile", 
        fetchUserProfile(userId),
        {
            onSuccess: (data) => {
                setUserProfile(data);
            },
            cacheTime: 0,
            refetchOnWindowFocus: false
        }
    );
    if (isLoading || isError) {
        return <FallbackAvatar />
    }
    
    return (
        <div className="small-roundel">
            {userProfile?.pictureUrl ? (
                <img src={userProfile?.pictureUrl} alt="avatar" />
            ) : (
                <DefaultAvatar 
                id={generateRandomNumber()} 
                firstName={userProfile?.firstName} 
                lastName={userProfile?.lastName} />
            )}
        </div>
    );
};

const AvatarMenu = () => {
    const [showDropdown, toggleDropdown] = useState(false);
    const [isOnline, toggleOnline] = useRecoilState(onlineStatusState);
    
    useInterval(() => {
        try {
            if (!navigator.onLine) toggleOnline(false);
        } catch (error) {
            console.log(error);
        }
    }, 10000);

    return ( 
        <div className={showDropdown ? "avatar-menu avatar-menu-open" : "avatar-menu"} 
        tabIndex="-1"
        onClick={() => {
            toggleDropdown(!showDropdown);
        }}
        onBlur={(e) => {
            if (e.relatedTarget?.className !== "link") toggleDropdown(false);
        }}>
            <Suspense fallback={<FallbackAvatar />}>
                <UserAvatar />
            </Suspense>
            <StatusIndicator status={isOnline ? "active" : "inactive"} />
            <img src={chevron} alt="chevron" className="small-icon" />
            {showDropdown ? (
                <div className="drop-down" style={{
                    top: `${offsetTop}px`
                }}>
                    {menuItems?.map((item, i) => (
                        <Link key={uuid()} to={item.to} className="link">
                            <span className="menu-item">
                                {item?.icon ? <img src={item?.icon} alt={item?.name} className="icon" /> : null}
                                <p>{item?.name}</p>
                            </span>
                        </Link>
                    ))}
                </div>
            ) : null}
        </div>
    );
}
 
export default AvatarMenu;