import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({children, path, ...rest}) => {
    const persistedToken = localStorage.getItem("soteriaToken");
    const isAuthorized = persistedToken;
    if (!isAuthorized) return (
        <Redirect to={{
            pathname: "/login",
            state: {
                target: path
            }
        }} />
    );

    return <Route {...rest}>{children}</Route>;
};
 
export default ProtectedRoute;