import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import brokenImage from '../graphics/broken.svg';

const Navigation = () => {
    const history = useHistory();
    return (
        <div className="btn-group">
            <button className="secondary-btn" onClick={(e) => {
                history.goBack();
            }}>Go back</button>
            <button className="action-btn" onClick={(e) => {
                history.push("/");
            }}>Home</button>
        </div>
    )
};

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() { 
        const dateString = new Date().toLocaleString();
        let mailBody = "Reporting client-side error with aaveg.nawgati.com.";
        let subject = `Error encountered with Aaveg Client at ${dateString}`;
        mailBody = mailBody.split(" ").join("%20");
        subject = subject.split(" ").join("%20");
        if (this.state.hasError) {
            return ( 
                <div className="page flex-center">
                    <div id="not-found">
                        <img src={brokenImage} alt="broken"></img>
                        <h1>Oops!</h1>
                        <h3>That was an error at our end.</h3>
                        <p>Try reloading the page, or <a href={`mailto:team@nawgati.com?subject=${subject}&body=${mailBody}`}>report an error</a></p>
                        <div style={{
                            height: "60px"
                        }}></div>
                        <Navigation />
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}
 
export default ErrorBoundary;