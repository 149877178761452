import { range } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { userState } from '../atoms/globalAtoms';
import { aavegService } from '../axios';
import Error from '../components/Error';
import Loader from '../components/Loader';
import GitGraph from '../components/viz/GitGraph';

const dayLength = 24 * 60 * 60 * 1000;

function fetchUserActivity(userId, year=new Date().getFullYear()) {
    return async function() {
        try {
            const startDate = new Date(year, 0);
            const endDate = new Date(year + 1, 0);
            const scope = 24 * 60 * 60 * 1000;
            const res = await aavegService.get(`/users/${userId}/activity`, {
                params: {
                    startDate,
                    endDate,
                    scope
                }
            });
            const startOffset = startDate.getDay();
            return res?.data?.map(item => {
                const { duration, startDate: itemStartDate } = item;
                const t = new Date(itemStartDate);
                const y = 6 - t.getDay();
                const x = Math.floor((t.getTime() - new Date(startDate).getTime() - (startOffset * dayLength)) / (7 * dayLength));
                return { x, y, value: Math.min(1, duration / dayLength), rawValue: Math.min(dayLength, duration), t };
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }
}

const Activity = () => {
    const { user: userId } = useRecoilValue(userState);
    const { isError, isLoading, data } = useQuery(
        "user-activity", 
        fetchUserActivity(userId), {
            refetchOnWindowFocus: false
        }
    );

    if (isLoading) return (
        <div id="user-activity">
            <Loader size="small" />
        </div>
    );

    if (isError) return (
        <div id="user-activity">
            <div className="body">
                <Error text="Unable to fetch invites" />
            </div>
        </div>
    );

    const currentYear = new Date().getFullYear();
    const startYear = new Date(data?.[0]?.t ?? "").getFullYear();
    const years = range(startYear, currentYear + 1, 1);

    return (
        <div id="user-activity">
            <section>
                <h2>History</h2>
                <GitGraph data={data} years={years} />
            </section>
        </div>
    );
};
 
export default Activity;