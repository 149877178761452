const standardPalette = [
    "#FCA311",
    "#36A4FF",
    "#FFDC24",
    "#6E1FF0",
    "#ff006e",
    "#17c3b2",
    "#9ef01a",
    "#072ac8",
    "#669bbc",
    "#ca6702",
];

export const congestionPalette = [
    "#F6F895",
    "#FFE608",
    "#ffba08",
    "#faa307",
    "#f48c06",
    "#e85d04",
    "#dc2f02",
    "#d00000",
    "#9d0208",
    "#6a040f",
];

export const coolPalette = [
    "#80FFDB",
    "#72EFDD",
    "#64DFDF",
    "#56CFE1",
    "#48BFE3",
    "#4EA8DE",
    "#5390D9",
    "#5E60CE",
    "#6930C3",
    "#7400B8",
];

export const linePalette = [
    "#2d00f7",
    "#6a00f4",
    "#8900f2",
    "#a100f2",
    "#b100e8",
    "#bc00dd",
    "#d100d1",
    "#db00b6",
    "#e500a4",
    "#f20089",
    "#F1003A",
    "#F14800",
    "#F18200",
    "#F1AE00",
    "#F1D900",
    "#DEF100",
    "#A4F100",
    "#5CF100",
    "#1FE30E",
    "#0EE270",
    "#0EE2BC",
    "#0EE2E2",
    "#0EBCE2",
    "#0EA3E2",
    "#0E89E2",
    "#0E23E2",
    "#450EE2",
    "#5F0EE2",
];

export const vehiclePalette = [
    "#D7D2CF",
    "#5F5CF6",
    "#F65CD5",
    "#44ACD9",
    "#446FD9",
    "#39B919",
    "#F9C235",
    "#FF993A",
    "#F65C65",
    "#A55325"
];

export const hexPalette = [
    "#001F97",
    "#027EC7",
    "#04D6ED",
    "#11FFF3",
    "#52FFB1",
    "#C3FF3E",
    "#FFEA00",
    "#FDA900",
    "#FC5600",
    "#DE0000"
];

export const heatPalette = [
    "#620F2D",
    "#822612",
    "#D82418",
    "#D7620E",
    "#D7930E",
    "#DECA1A",
];

export const heatPalette2 = [
    "#A00543",
    "#C12C4B",
    "#E55649",
    "#FA9052",
    "#FEDF8C",
    "#FEFEBD"
];

export const heatPalette3 = [
    "#D92A2A",
    "#FB3225",
    "#FB5825",
    "#FB9925",
    "#FBD125",
    "#F0F823"
];

export const heatPalette4 = [
    "#E3231B",
    "#EC8923",
    "#F5FD2F",
    "#67F925",
    "#65FBF2",
    "#0E16F7"
];

export const heatPalette5 = [
    "#081D58",
    "#253494",
    "#1D91C0",
    "#42B6C4",
    "#C7E9B4",
    "#FFFFD9"
];

export const palette = {
    heatPalette,
    heatPalette2,
    heatPalette3,
    heatPalette4,
    heatPalette5,
};

export default standardPalette;