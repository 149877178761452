import React from 'react';

const CustomHint = ({ 
    datapoint = { x: null, y: null }, 
    formatX = x => x, 
    formatY = y => y, 
    getX = d => d.x, 
    getY = d => d.y, 
    getTitle = d => d.title,
    getColor = d => d.color,
    className=""
}) => {
    if (!datapoint) return null;
    const x = formatX(getX(datapoint));
    const y = formatY(getY(datapoint));
    const title = getTitle(datapoint);
    const color = getColor(datapoint);
    return ( 
        <div className={["hint", className].filter(d => d).join(" ")}>
            {x ? (
                <h4 className="crosshair-title">{x}</h4>
            ) : (null)}
            <div className="crosshair-body">
                {color && (
                    <div className="color-rect" style={{
                        backgroundColor: color
                    }}></div>
                )}
                {title && (
                    <p className="crosshair-item-title">{title}:</p>
                )}
                <p className="crosshair-value">{y}</p>
            </div>
        </div>
    );
}
 
export default CustomHint;