import React, { useState } from 'react';
import uuid from 'react-uuid';
import chevron from '../../graphics/chevron.svg';

function computeSize(items=[], transformer) {
    const sizes = items.map(item => transformer ? String(transformer(item)).length : String(item).length);
    const maxSize = Math.max(...sizes);
    return `${Math.max(maxSize * 8 + 15, 70)}px`;
}

const Select = ({
    menuItems, 
    currentItem, 
    setCurrentItem, 
    transformer, 
    disabled=false,
    adaptive=false
}) => {
    const [showDropdown, toggleDropdown] = useState(false);
    if (!menuItems || !menuItems.length) return null;
    const isDisabled = disabled || menuItems.length <= 1;
    const remainingItems = menuItems.filter(item => {
        if (transformer) {
            return transformer(item) !== transformer(currentItem);
        }
        return item !== currentItem;
    });
    return ( 
        <div className={showDropdown ? "select-open select" : "select"} 
        style={adaptive ? {
            width: isDisabled ? "fit-content" : computeSize(menuItems, transformer)
        } : null}
        tabIndex="-1"
        onClick={() => {
            if (!isDisabled && remainingItems?.length) toggleDropdown(!showDropdown);
        }}
        onBlur={() => {
            toggleDropdown(false);
        }}>
            <div className={isDisabled ? "current-menu-item disabled-select" : "current-menu-item"}>
                <p>{transformer ? transformer(currentItem) : currentItem}</p>
                <img src={chevron} alt="chevron" className="small-icon chevron" />
            </div>
            {!isDisabled ? (
                <div className="drop-down" style={{
                    height: showDropdown ? `${remainingItems?.length * 100}%` : 0
                }}>
                    {remainingItems?.map((item, i) => (
                        <span key={uuid()} className="menu-item" onClick={() => {
                            setCurrentItem(item);
                        }}>
                            <p>{transformer ? transformer(item) : item}</p>
                        </span>
                    ))}
                </div>
            ) : (null)}
        </div>
    );
}
 
export default Select;