import React, { lazy, Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import uuid from 'react-uuid';
import { useQuery } from 'react-query';
import { Link, useLocation, useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';

import { userState } from '../atoms/globalAtoms';

import { aavegService } from '../axios';

import Loader from '../components/Loader';
import Error from '../components/Error';
import Activity from '../subViews/Activity';

const Invites = lazy(() => import('../subViews/Invites'));
const ProfileForm = lazy(() => import('../subViews/ProfileForm'));
const ChangePasswordForm = lazy(() => import('../subViews/ChangePasswordForm'));

const routes = [
    {
        name: "Basic Details",
        path: "/basic-details",
    },
    {
        name: "Change Password",
        path: "/change-password",
    },
    {
        name: "Activity",
        path: "/activity"
    },
    {
        name: "Invites",
        path: "/invites"
    }
];

const VerticalMenu = () => {
    const { url } = useRouteMatch();
    const location = useLocation();
    return (
        <div className="vertical-menu">
        {routes?.map((route) => {
            const fullPath = `${url}${route.path}`;
            const className = 
                fullPath === location.pathname 
                ? "vertical-menu-item selected-menu-item" 
                : "vertical-menu-item";
            return (
                <Link key={uuid()} to={fullPath}>
                    <div className={className}>{route.name}</div>
                </Link>
            );
        })}
        </div>
    );
}

function fetchUserProfile(userId) {
    return async function() {
        try {
            const res = await aavegService.get(`/users/${userId}`);
            return res.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
}

const Profile = () => {
    const { user: userId } = useRecoilValue(userState);
    const { data: userProfile, isLoading, isError } = useQuery(
        "user-profile", 
        fetchUserProfile(userId),
        {
            refetchOnWindowFocus: false
        }
    );
    if (isLoading) return (
        <div className="page" id="profile">
            <Loader text="Fetching profile.." />
        </div>
    );
    if (isError) return (
        <div className="page" id="profile">
            <Error text="Couldn't fetch profile" />
        </div>
    );
    return ( 
        <div className="page" id="profile">
            <div className="block-center">
                <VerticalMenu />
                <div className="block">
                    <Suspense fallback={<Loader size="small" />}>
                        <Switch>
                            <Route path="/profile/basic-details">
                                <ProfileForm userProfile={userProfile} />
                            </Route>
                            <Route path="/profile/change-password">
                                <ChangePasswordForm userProfile={userProfile} />
                            </Route>
                            <Route path="/profile/activity">
                                <Activity />
                            </Route>
                            <Route path="/profile/invites">
                                <Invites userId={userId} />
                            </Route>
                            <Redirect to="/profile/basic-details" />
                        </Switch>
                    </Suspense>
                </div>
            </div>
        </div>
    );
}
 
export default Profile;