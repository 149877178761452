import axios from "axios";
import { isObject } from "lodash";
import { atom, atomFamily, selectorFamily } from "recoil";
import settings from "../defaults";
import { getTimeDomainDefault } from "../utils";

const persistedToken = localStorage.getItem("soteriaToken");
if (persistedToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + persistedToken;
}

const localStorageEffect = key => ({setSelf, onSet}) => {
    let savedValue = localStorage.getItem(key);
    if (savedValue != null) {
        try {
            savedValue = JSON.parse(savedValue);
        } catch (error) {
            // do nothing
        }
        setSelf(savedValue);
    }
  
    onSet(newValue => {
        if (newValue) localStorage.setItem(key, isObject(newValue) ? JSON.stringify(newValue) : newValue);
    });
};

export const userIdState = atom({
    key: "userIdState",
    default: null,
    effects_UNSTABLE: [
        localStorageEffect("userId")
    ]
});

export const userState = atom({
    key: "userState",
    default: { user: null, role: null, permissions: null },
    effects_UNSTABLE: [
        localStorageEffect("user")
    ]
});

export const globalSearchQueryState = atomFamily({
    key: "globalSearchQueryState",
    default: (id) => {
        return "";
    }
});

export const globalAppConfigState = atomFamily({
    key: "globalAppConfigState",
    default: selectorFamily({
        key: "globalAppConfigStateDefault",
        get: (id) => ({get}) => {
            return {};
        }
    })
});

export const settingsState = atomFamily({
    key: "settingsState",
    default: (key) => {
        const setting = settings?.[key];
        if (setting) return setting;
        return {};
    }
});

function getDefault(key) {
    let value = "";
    switch (key) {
        case "cumulativeCount1h":
        case "serveTime1h":
        case "waitTime10m":
        case "bayUtilization1h":
            value = "today";
            break;
    
        default:
            break;
    }
    return getTimeDomainDefault(value);
}

export const timeDomainState = atomFamily({
    key: "timeDomainState",
    default: (id) => {
        return getDefault(id);
    }
});

export const timeDomainDuplicateState = atomFamily({
    key: "timeDomainDuplicateState",
    default: selectorFamily({
        key: "timeDomainDuplicateStateDefault",
        get: (id) => ({get}) => {
            return get(timeDomainState(id));
        }
    })
});

export const highlightModeState = atomFamily({
    key: "highlightModeState",
    default: (id) => false
});

export const highlightValueState = atomFamily({
    key: "highlightValueState",
    default: (id) => null
});

export const timeRangeState = atomFamily({
    key: "timeRangeState",
    default: (id) => []
});

export const timePlayerState = atomFamily({
    key: "timePlayerState",
    default: (id) => ({
        position: 0,
        isPlaying: false
    })
});

export const updateDataState = atomFamily({
    key: "updateDataState",
    default: () => {
        return true;
    }
});

export const update60sState = atomFamily({
    key: "update60sState",
    default: (id) => {
        return 0;
    }
});

export const update1sState = atomFamily({
    key: "update1sState",
    default: (id) => {
        return 0;
    }
});

export const onlineStatusState = atom({
    key: "onlineStatusState",
    default: false
});

export const filterState = atomFamily({
    key: "filterState",
    default: (id) => {}
});

export const snapshotMenuState = atom({
    key: "snapshotMenuState",
    default: false
});

export const snapshotState = atom({
    key: "snapshotState",
    default: { domainId: null, type: "range", path: "", text: "" }
});