import React, { Suspense, lazy, useEffect, useState } from 'react';
import axios from 'axios';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { onlineStatusState } from './atoms/globalAtoms';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/Header';
import Loader from './components/Loader';
import ProtectedRoute from './customRoutes/ProtectedRoute';
import Profile from './views/Profile';
import Footer from './components/Footer';
import StationNavigator from './partials/StationNavigator';
import { aavegService, authService } from './axios';
import { decodePayload } from './utils';

const Landing = lazy(() => import('./views/Landing'));
const Login = lazy(() => import('./views/Login'));
const Signup = lazy(() => import('./views/Signup'));
const Overview = lazy(() => import('./views/Overview'));
const Routing  = lazy(() => import('./views/Routing'));
const Asset = lazy(() => import('./views/Asset'));
const NotFound = lazy(() => import('./views/NotFound'));
const AddStation = lazy(() => import('./views/AddStation'));
const Incidents = lazy(() => import('./views/Incidents'));
const Metrics = lazy(() => import('./views/Metrics'));
const Reports = lazy(() => import('./views/Reports'));
// const Faq = lazy(() => import('./views/Faq'));
const Construction = lazy(() => import('./views/Construction'));


const Fallback = () => {
  return (
    <div className="page">
      <Loader />
    </div>
  );
};

async function updateToken() {
  let refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) throw new Error();
  let soteriaToken;
  const res = await authService.get("/extend", {
    params: {
      refreshToken
    }
  });
  ({ soteriaToken, refreshToken } = res?.data);
  const { data, exp } = decodePayload(soteriaToken);
  const { user, role, permissions } = data;
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("soteriaToken", soteriaToken);
  axios.defaults.headers.common["Authorization"] = `Bearer ${soteriaToken}`;
  return { soteriaToken, refreshToken, user, role, permissions, exp };
}

let isRefreshing = null;

function App() {
  const toggleOnline = useSetRecoilState(onlineStatusState);
  const history = useHistory();
  const location = useLocation();
  const [showStationNavigator, toggleStationNavigator] = useState(false);

  useEffect(() => {
    aavegService.interceptors.response.use(function(response) {
      toggleOnline(true);
      return response;
    }, function(error) {
      if (error?.response?.status !== 401) return Promise.reject(error);
      
      if (isRefreshing === null) {
        isRefreshing = refreshMutation.mutateAsync(undefined, {
          onSettled: () => isRefreshing = null,
          onError: () => {
            history.push("/login", {
              logout: true,
              target: location
            });
          }
        })
      }

      const config = error.config;
      delete config.headers["Authorization"];

      return isRefreshing.then(() => aavegService.request(config));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isAuthorized = localStorage.getItem("soteriaToken");
    const escapeListener = (e) => {
      if (e.key === "Escape") {
        toggleStationNavigator(false);
      }
    };
    const stationListener = (e) => {
      if (!isAuthorized) return null;
      const event = e || window.event; // for IE to cover IEs window event-object
      if (event.altKey && event.which === 83) {
        toggleStationNavigator(true);
        return false;
      }
    };
    document.addEventListener("keydown", escapeListener);
    document.addEventListener("keyup", stationListener);
    return () => {
      document.removeEventListener("keydown", escapeListener);
      document.removeEventListener("keyup", stationListener);
    }
  }, []);

  const refreshMutation = useMutation(updateToken);

  return (
    <ErrorBoundary>
      <div className="App">
        <Header />
        <StationNavigator showMenu={showStationNavigator} toggleMenu={toggleStationNavigator} />
        <Suspense fallback={<Fallback />}>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/signup">
              <Signup />
            </Route>
            <ProtectedRoute exact path="/overview">
              <Overview />
            </ProtectedRoute>
            <ProtectedRoute path="/overview">
              <Overview />
            </ProtectedRoute>
            <ProtectedRoute exact path="/stations/add">
              <AddStation mode="add" />
            </ProtectedRoute>
            <ProtectedRoute path="/stations/:slug">
              <Asset />
            </ProtectedRoute>
            <ProtectedRoute exact path="/stations/:slug/edit">
              <AddStation mode="edit" />
            </ProtectedRoute>
            <ProtectedRoute path="/incidents">
              <Incidents />
            </ProtectedRoute>
            <ProtectedRoute exact path="/routing">
              <Routing />
            </ProtectedRoute>
            <ProtectedRoute path="/reports">
              <Reports />
            </ProtectedRoute>
            <ProtectedRoute path="/metrics">
              <Metrics />
            </ProtectedRoute>
            <ProtectedRoute path="/profile">
              <Profile />
            </ProtectedRoute>
            <Route exact path="/help">
              <Construction />
            </Route>
            <Route exact path="/">
              <Landing />
            </Route>
            <Redirect from="/stations" to="/overview" />
            <Route>
              <NotFound />
            </Route>
            <Redirect to="/" />
          </Switch>
        </Suspense>
        <Footer />
      </div>
    </ErrorBoundary>
  );
}

export default App;
