import React from 'react';
import { useRecoilState } from 'recoil';
import { globalSearchQueryState } from '../atoms/globalAtoms';

import searchIcon from '../graphics/search.svg';

const ClientSearch = ({ searchId, placeholder, autoFocus=false }) => {
    const [query, setQuery] = useRecoilState(globalSearchQueryState(searchId));
    return (
        <div className="flex-search">
            <img src={searchIcon} alt="search" className="icon" />
            <input
            type="search" 
            placeholder={placeholder}
            value={query}
            onChange={(e) => {
                setQuery(e.target.value);
            }}
            autoFocus={autoFocus} />
        </div>
    );
}
 
export default ClientSearch;