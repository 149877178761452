import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import uuid from 'react-uuid';
import SearchBar from './SearchBar';

export const Burger = ({isOpen, toggleOpen}) => {
    return (
        <div 
        className={isOpen ? "hamburger-close hamburger-btn" : "hamburger-btn"}
        onClick={() => {
            toggleOpen(!isOpen);
        }}>
            <div className="ham-top"></div>
            <div className="ham-cheese"></div>
            <div className="ham-bottom"></div>
        </div>
    );
};

const Hamburger = ({routes}) => {
    const [isOpen, toggleOpen] = useState(false);
    return ( 
        <div className="hamburger">
            <Burger isOpen={isOpen} toggleOpen={toggleOpen} />
            {isOpen ? (
                <div className="hamburger-menu">
                    <SearchBar />
                    <div className="vertical-menu">
                    {routes?.map((route, i) => (
                        <NavLink 
                        key={uuid()} 
                        to={route?.route} 
                        activeStyle={{
                            color: "#eb9812"
                        }}
                        onClick={() => {
                            toggleOpen(false);
                        }}>
                            <div className="vertical-menu-item">{route?.name}</div>
                        </NavLink>
                    ))}
                    </div>
                </div>
            ) : (null)}
        </div>
    );
}
 
export default Hamburger;