import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import TabMenu from './TabMenu';
import AvatarMenu from './AvatarMenu';
import aavegIcon from '../graphics/aaveg.svg';
import { useHistory, useLocation } from 'react-router-dom';
import Hamburger from './Hamburger';

const routes = [
    {
        route: "/overview",
        name: "stations"
    },
    {
        route: "/incidents",
        name: "incidents"
    },
    {
        route: "/routing",
        name: "routing"
    },
    {
        route: "/metrics",
        name: "metrics"
    },
    {
        route: "/reports",
        name: "reports"
    }
];

const publicRoutes = [
    "/",
    "/signup",
    "/login"
];

const nonSearchRoutes = [
    "/help"
];

const Header = () => {
    const history = useHistory();
    const location = useLocation();
    const [showHeader, toggleHeader] = useState(true);
    const [showSearch, toggleSearch] = useState(true);
    useEffect(() => {
        let path = location.pathname;
        if (publicRoutes.includes(path)) {
            toggleHeader(false);
        } else {
            toggleHeader(true);

            if (nonSearchRoutes.includes(path)) {
                toggleSearch(false);
            } else toggleSearch(true);
        }
    }, [location]);
    if (!showHeader) return null;
    return ( 
        <header>
            <img src={aavegIcon} alt="aaveg" className="small-logo" onClick={() => {
                history.push("/");
            }} />
            {showSearch ? <SearchBar /> : null}
            <TabMenu routes={routes} />
            <AvatarMenu />
            <Hamburger routes={routes} />
        </header>
    );
}
 
export default Header;