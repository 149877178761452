import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import searchIcon from '../graphics/search.svg';
import { useRecoilState, useRecoilValue } from 'recoil';
import { globalSearchQueryState } from '../atoms/globalAtoms';
import Loader from '../components/Loader';
import { globalSearchResultsState } from '../selectors/globalSelectors';
import { useLocation } from 'react-router';

const StationResult = ({result}) => {
    return (
        <div className="text-content">
            <h4>{result?.alias || result?.name}</h4>
            <p>{result?.address}</p>
        </div>
    );
};

const UserResult = ({result}) => {
    const name = result.firstName + " " + result.lastName;
    return (
        <div className="text-content">
            <h4>{name}</h4>
            <p>{`${result?.title}, ${result?.organization}`}</p>
        </div>
    );
};

const OrganizationResult = ({result}) => {
    return (
        <div className="text-content">
            <h4>{result?.name || result?.alias}</h4>
            <p>{`${result?.assets} stations`}</p>
        </div>
    );
};

const getResult = (type) => {
    if (type === "user") return UserResult;
    else if (type === "organization") return OrganizationResult;
    else if (type === "station") return StationResult;
}

const getBasePath = (type) => {
    if (type === "station") return "stations";
    else if (type === "user") return "users";
    else if (type === "organization") return "organizations";
}

const constructPath = (type="", slug="") => {
    const parts = [getBasePath(type), slug].filter(part => part);
    return `/${parts.join("/")}`;
};

const SearchResult = ({result={}}) => {
    const { type, slug } = result;
    const path = constructPath(type, slug);
    const Result = getResult(type);
    return (
        <Link className="search-link" to={path}>
            <div className="search-result">
                <Result result={result} />
            </div>
        </Link>
    );
}

const getPlaceholder = (type) => {
    let placeholder = "";
    switch (type) {
        case "stations":
            placeholder = "Search for stations"
            break;

        case "users":
            placeholder = "Search for people"
            break;

        case "organizations":
            placeholder = "Search for organizations"
            break;
    
        default:
            placeholder = "Search for stations, people or organizations"
            break;
    }
    return placeholder;
};

const SearchResults = ({searchId, type}) => {
    const searchResults = useRecoilValue(globalSearchResultsState({id: searchId, type}));
    if (!searchResults.length) return null;
    return (
        <div className="search-results">
            {searchResults?.map(result => (
                <SearchResult key={uuid()} result={result} />
            ))}
        </div>
    );
};

const SearchLoader = () => {
    return (
        <div className="search-results" style={{
            height: "100px"
        }}>
            <Loader size="tiny" />
        </div>
    );
};

// type can be "", "stations", "users", "organizations"
const SearchBar = ({type="", hint=true, searchId=123, showExplicitResults=true}) => {
    const location = useLocation();
    const [query, setQuery] = useRecoilState(globalSearchQueryState(searchId));
    const [showResults, toggleShowResults] = useState(false);
    const [showHint, toggleHint] = useState(hint);
    const ref = useRef();

    useEffect(() => {
        if (hint) {
            function focusListener(e) {
                if (e.key === "/") {
                    if (ref.current && e.target?.nodeName === "BODY") {
                        ref.current?.focus();
                    }
                }
            }
            document.body.addEventListener("keydown", focusListener);
            return () => {
                document.body.removeEventListener("keydown", focusListener);
            };
        }
    }, [hint]);

    useEffect(() => {
        toggleShowResults(false);
    }, [location]);

    return ( 
        <div
        tabIndex={-1}
        className="flex-search"
        onBlur={(e) => {
            if (e.relatedTarget?.className !== "search-link") {
                toggleHint(true);
                toggleShowResults(false);
            }
        }}
        onClick={() => {
            toggleShowResults(true);
        }}>
            <img src={searchIcon} alt="search" className="icon" />
            {hint && showHint ? (
                <div className="flat-hint">/</div>
            ) : (null)}
            <input 
            ref={ref}
            type="search" 
            placeholder={getPlaceholder(type)}
            value={query}
            onChange={(e) => {
                setQuery(e.target.value);
            }}
            onFocus={() => {
                toggleHint(false);
            }} />
            {showExplicitResults && showResults ? (
                <Suspense fallback={<SearchLoader />}>
                    <SearchResults searchId={searchId} type={type} />
                </Suspense>
            ) : (null)}
        </div>
    );
}
 
export default SearchBar;