import React from 'react';
import { createPortal } from 'react-dom';

const Modal = ({children, containerId}) => {
    let modalContainer = document.getElementById(containerId);
    if (!modalContainer) modalContainer = document.body;
    return createPortal( 
        <div className="modal-container">
            {children}
        </div>,
        modalContainer
    );
};
 
export default Modal;