import React from 'react';

const Loader = ({text, size="regular", inline=false}) => {
    let className;
    if (size === "tiny") className = "loader-case-tiny";
    else if (size === "small") className = "loader-case-small";
    else className = "loader-case";
    return ( 
        <div className={inline ? "loader-frame inline" : "loader-frame"}>
            <div className={className}>
                <div className="loader"></div>
            </div>
            {text ? <div className="loader-text">{text}</div> : null}
        </div>
    );
}
 
export default Loader;