import React from 'react';
import Help from './Help';

const statusMatrix = {
    "active": {
        backgroundColor: "#5FD954",
    },
    "pending data": {
        backgroundColor: "#EF2CA1",
    },
    "inactive": {
        backgroundColor: "#F23838",
    },
    "decomissioned": {
        backgroundColor: "#ACACAC",
    }
};

const StatusIndicator = ({status}) => {
    return ( 
        <Help
        message={status}>
            <div
            className="status-indicator" 
            style={statusMatrix?.[status]}></div>
        </Help>
    );
};
 
export default StatusIndicator;