import axios from 'axios';

export const aavegService = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_PROD
});

aavegService.interceptors.request.use(function(config) {
    const token = localStorage.getItem("soteriaToken");
    if (!token) {
        throw new axios.Cancel("auth token absent");
    }
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
}, function(error) {
    return Promise.reject(error);
});

export const authService = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL_PROD
});

export const fileService = axios.create({
    baseURL: process.env.REACT_APP_FILE_URL_PROD
});