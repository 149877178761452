/* eslint-disable no-self-compare */
import { palette } from "./palette";
import { filter, get, inRange, padStart, pick, range, sortBy } from 'lodash';
import ms from "ms";

export const getDayDifference = (d1, d2) => {
    const delta = d1.getTime() - d2.getTime();

    let days = Math.floor(delta / (24 * 60 * 60 * 1000));
    let verb = null;
    if (days === 0) {
        const diff = d1.getDay() - d2.getDay();
        if (diff > 0) days = diff;
    } 
    verb = days > 0 ? "ago" : "later"; 
    return { days, verb };
}

export const formatDT = (date, options={
    time: true,
    date: false
}, order="normal") => {
    let fDate = date;
    if (typeof date === "number" || typeof date === "string") fDate = new Date(date);
    try {
        let localeString = fDate?.toLocaleString("en-US", {
            timeStyle: "short",
            dateStyle: "medium"
        });
        let localeArr = localeString?.split(", ");
        if (options?.time && options?.date) {
            const arr = [localeArr[2], localeArr[0]];
            if (order !== "normal") arr.reverse()
            return arr.join(", ");
        }
        else if (options?.time) return localeArr[2];
        else if (options?.date) return localeArr[0];
        else {
            const now = new Date();
            const { days, verb } = getDayDifference(now, fDate);
            if (days === 1) {
                return `${localeArr[2]}, yesterday`;
            } else if (days > 1) {
                return `${localeArr[2]}, ${days} days ${verb}`;
            }
            return `${localeArr[2]}`;
        }
    } catch (error) {
        console.log(error);
    }  
};

export function autoFormatDate(date, auto=true, options={ date: true, time: true }, format={
    date: {
        dayOfWeek: false,
        day: true,
        month: true,
        year: true,
    },
    time: {
        hours: true,
        minutes: true,
        seconds: false
    }
}) {
    try {
        let formattedDate = date;
        if (typeof date === "string" || typeof date === "number") {
            formattedDate = new Date(date);
        } else if (typeof date === "object" && !date?.getTime()) {
            throw new Error("Invalid date object");
        }

        const [dayOfWeek, month, day, year, time] = formattedDate.toString().split(" ");
        let [hours, minutes, seconds] = time.split(":");
        const now = new Date();

        let timeString = "";
        let dateString = "";
        
        if (auto) {
            if (format.date.dayOfWeek) {
                dateString += `${dayOfWeek}, `;
            }
            if (+day !== now.getDate() || Math.abs(now.getTime() - formattedDate.getTime()) > timeConversion.days) {
                dateString += `${month} ${day}`;
            } 
            if (+year !== now.getFullYear()) {
                if (+day === now.getDate() && +month === now.getMonth()) {
                    dateString += `${month} ${day}, ${year}`;
                } else dateString += `, ${year}`;
            }
            if (+hours >= 12) {
                timeString += `${hours > 12 ? hours - 12 : 12}:${minutes}`;
                if (format.seconds) timeString += `:${seconds}`;
                timeString += " PM";
            } else {
                timeString += `${hours > 0 ? hours : 12}:${minutes}`;
                if (format.seconds) timeString += `:${seconds}`;
                timeString += " AM";
            }
            return [dateString, timeString].filter(d => d).join(", ");
        }

        if (options.date) {
            if (format.date.dayOfWeek) {
                dateString += `${dayOfWeek}, `;
            }       
            if (format.date.year) {
                dateString += `${month} ${day}, ${year}`;
            } else {
                dateString += `${month} ${day}`;
            }
        }

        if (options.time) {
            if (+hours >= 12) {
                timeString += `${hours > 12 ? hours - 12 : 12}:${minutes}`;
                if (format.seconds) timeString += `:${seconds}`;
                timeString += " PM";
            } else {
                timeString += `${hours > 0 ? hours : 12}:${minutes}`;
                if (format.seconds) timeString += `:${seconds}`;
                timeString += " AM";
            }
        }

        return [dateString, timeString].filter(d => d).join(", ");
    } catch (error) {
        return null;
    }
}

export const formatHr = (hr) => {
    if (hr > 12) {
        hr = hr - 12;
        return `${hr} PM`;
    } else if (hr === 0) return "12 AM";
    else if (hr === 12) return "12 PM";
    else if (!hr) return null;
    return `${hr} AM`;
};

export const verifyDate = (d) => {
    try {
        if (!d) return false;
        if (!Number.isNaN(+d)) d = +d;
        const r0 = new Date(d);
        return r0.getTime() === r0.getTime();
    } catch (error) {
        return false;          
    }
}

export const validateDate = (d) => {
    if (d === null || d === undefined) throw new Error("Undefined date");
    if (!Number.isNaN(+d)) d = +d;
    const r0 = new Date(d);
    if (r0.getTime() !== r0.getTime()) throw new Error("Invalid date");
    return r0;
}

export const verifyDateRange = (from, to, range) => {
    try {
        if (!from || !to || !verifyDate(from) || !verifyDate(to)) return false;
        const r1 = new Date(from);
        const r2 = new Date(to);
        
        const delta = r2.getTime() - r1.getTime();
        if (delta < 0) return false;
        
        if (range) {
            if (!inRange(delta, range?.[0], range?.[1])) return false;
        }
        return true;
    } catch (error) {
        console.log(error);
        return false;        
    }
}

export const validateDateRange = (from, to, range) => {
    const startDate = validateDate(from);
    const endDate = validateDate(to);
    
    const delta = endDate.getTime() - startDate.getTime();
    if (delta < 0) throw new Error("Invalid date range");
    
    if (range) {
        if (!inRange(delta, range?.[0], range?.[1])) throw new Error("Delta outside range");
    }
    return { startDate, endDate };
}

export function binDatesByFrequency(d1, d2, f, keepLast=true) {
    try {
        const isValidRange = verifyDateRange(d1, d2);
        if (isValidRange) throw new Error("Invalid time range received");
        else if (Number.isNaN(f)) throw new Error("Invalid frequency value received");

        const bins = range(new Date(d1).getTime(), new Date(d2).getTime(), f);
        if (keepLast) {
            bins.push(d2);
        }
        return bins.map(b => new Date(b));
    } catch (error) {
        console.log(error);
        return [];
    }
    
}

const timeConversion = {
    weeks: 7 * 24 * 60 * 60 * 1000,
    days: 24 * 60 * 60 * 1000,
    hours: 60 * 60 * 1000,
    minutes: 60 * 1000,
    seconds: 1000
};

export const formatDurationMS = (durationMS, format={
    weeks: false, 
    days: false, 
    hours: true, 
    minutes: true, 
    seconds: true
}, keepShort=false) => {
    let remainder = durationMS;
    const output = [];

    if (typeof durationMS === "number") {
        if (durationMS === 0) return 0;
        for (let [key, value] of Object.entries(timeConversion)) {
            if (format[key]) {
                const result = Math.floor(remainder / value);
                if (result > 0) {
                    if (keepShort) {
                        output.push(`${result}${key.slice(0, 1)}`);
                    } else {
                        output.push(`${result} ${result > 1 ? key : key.slice(0, key.length - 1)}`);
                    }
                    // eslint-disable-next-line no-const-assign
                    remainder -= result * value;
                }
            }
        }
        return output.join(", ");
    }
};

export const convertDurationMS = (durationMS, format="minutes", precision=2, keepShort=false) => {
    const factor = timeConversion?.[format];
    if (!factor) return null;

    let result = (durationMS / factor);
    if (!Number.isInteger(result)) {
        result = result.toPrecision(precision);
    }
    let verb = format;
    if (keepShort) {
        verb = format.slice(0, 1);
        return `${result}${verb}`;
    }
    return `${result} ${verb}`;
};

export const padNumber = (num, digits=2) => {
    if (typeof num === "string") {
        const parsedNum = parseInt(num);
        if (Number.isNaN(parsedNum)) return null;
        return padStart(String(parsedNum), digits, "0");
    } else if (typeof num === "number") {
        return padStart(String(Math.trunc(num)), digits, "0");
    } 
    return null;
}

export const computeTimeDelta = (parts=[]) => {
    if (parts.length < 2) return null;
    let factor = 1;
    switch (parts[1]) {
        case "s":
        case "sec":
        case "secs":
        case "second":
        case "seconds":
            factor = timeConversion.seconds
            break;

        case "m":
        case "min":
        case "mins":
        case "minute":
        case "minutes":
            factor = timeConversion.minutes
            break;

        case "h":
        case "hr":
        case "hrs":
        case "hour":
        case "hours":
            factor = timeConversion.hours
            break;

        case "d":
        case "day":
        case "days":
            factor = timeConversion.days
            break;

        case "w":
        case "week":
        case "weeks":
            factor = timeConversion.weeks
            break;
    
        default:
            break;
    }
    return +parts[0] * factor;
}

export function getTimeDomainDefault(timeStr="") {
    let endDate = new Date();
    let startDate;

    if (timeStr === "today") {
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(0, 0, 0, 0);
        startDate = new Date(endDate.getTime() - ms("1d"));
    } else if (timeStr === "yesterday") {
        endDate.setHours(0, 0, 0, 0);
        startDate = new Date(endDate.getTime() - ms("1d"));
    } else if (timeStr.startsWith("last")) {
        const timeExp = timeStr.split(" ").slice(1).join(" ");
        startDate = new Date(endDate.getTime() - ms(timeExp));
    } else {
        startDate = new Date(endDate.getTime() - ms("1h"));
    }
    
    return { startDate, endDate };
}

export const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const fuels = [
    "petrol",
    "diesel",
    "cng",
    "electric"
];

export const statuses = [
    "active",
    "inactive",
    "pending data",
    "decommissioned"
];

/* export const vehicleTypes = [
    "bike",
    "auto",
    "car",
    "hmv"
]; */
export const vehicleTypes = [
    "auto",
    "car",
    "hmv"
];

export const allChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const generateRandomInteger = (limits=[0, 10]) => {
    let x = Math.random();
    return Math.round((1 - x) * limits[0]) + (x * limits[1]);
};

export const generateRandomNumber = (limits=[0, 10]) => {
    let x = Math.random();
    return ((1 - x) * limits[0]) + (x * limits[1]);
};

export const generateRandomString = (length) => {
    let result = "";
    for (let i = 0; i < length; i++) {
        result += allChars?.charAt(Math.floor(Math.random() * allChars?.length));
    }
    return result;
};

export const sortString = (first, second) => {
    if (first > second) return 1;
    else if (first < second) return -1;
    return 0;
};

export const computeBounds = (coordinates=[]) => {
    if (!coordinates || !coordinates.length) return null;
    let lats = coordinates?.map(coord => coord?.[1]);
    let longs = coordinates?.map(coord => coord?.[0]);
    let swBounds = [Math.min(...longs), Math.min(...lats)];
    let neBounds = [Math.max(...longs), Math.max(...lats)];
    return [swBounds, neBounds];
};

export const computeCentroid = (coordinates=[]) => {
    if (!coordinates || !coordinates.length) return null;
    let latSum = coordinates?.map(coord => coord?.[1])?.reduce((a, b) => a + b, 0);
    let longSum = coordinates?.map(coord => coord?.[0])?.reduce((a, b) => a + b, 0);
    
    return [longSum / coordinates.length, latSum / coordinates.length];
};

export const isValidCoordinates = (lng, lat) => {
    if (lat === null || lng === null || lat === "" || lat === "" || isNaN(lat) || isNaN(lng) || Math.abs(+lat) > 90.0 || Math.abs(+lng) > 180.0) return false;
    return true;
};

export const generateRandomHexCode = (seed=1) => {
    let code = Math.floor((Math.abs(Math.sin(seed) * 16777215) % 16777215));
    code = code.toString(16);
    while (code.length < 6) {
        code = "0" + code;
    }
    return code;
};

function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, "0");   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

export const generateHexFromString = (word, s=100, l=50, seed=1) => {
    const chars = word.split("");
    const h = chars.reduce((prev, next) => prev + next.charCodeAt(0), 0) * seed % 360;

    return hslToHex(h, s, l);
};

export const isElementInViewport = (elem) => {

    if (!elem) return false;
    let rect = elem.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && 
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) 
    );
}

// svg utilities

const PI = Math.PI;

export function computeSlope({startPoint, endPoint}) {
    let m, angle, rotation;
    if (!startPoint || !endPoint) return { m, angle, rotation };
    const dy = endPoint.y - startPoint.y;
    const dx = endPoint.x - startPoint.x;
    if (dx === 0) return { angle: PI / 2, rotation: 90, m: null };
    else if (dy === 0) return { angle: dx > 0 ? 0 : PI, rotation: dx > 0 ? 0 : 180, m: 0 };
    m = dy / dx;
    angle = Math.atan(m);
    rotation = angle * 180 / PI;
    return { rotation, angle, m };
}

export function computeMidpoint({startPoint, endPoint}) {
    const xMid = 0.5 * (startPoint.x + endPoint.x);
    const yMid = 0.5 * (startPoint.y + endPoint.y);
    return { xMid, yMid };
}

export function computeDistance({startPoint, endPoint}) {
    const dy = endPoint.y - startPoint.y;
    const dx = endPoint.x - startPoint.x;
    const d = Math.sqrt((dx ** 2) + (dy ** 2));
    return d;
}

export function pan(matrix, dx=0, dy=0) {
    const newMatrix = [...matrix];
    try {
        newMatrix[4] += dx;
        newMatrix[5] += dy;
        return newMatrix;
    } catch (error) {
        console.log(error);
    }
}

const defaultMatrix = [1, 0, 0, 1, 0, 0];
export function zoom(matrix, scale=1, center={ x: 0, y: 0 }) {
    const newMatrix = [...matrix];
    try {
        for (let i = 0; i < 4; i += 1) {
            newMatrix[i] = scale * defaultMatrix[i]
        }
        newMatrix[4] += ((1 - scale) * center.x);
        newMatrix[5] += ((1 - scale) * center.y);
        return newMatrix;
    } catch (error) {
        console.log(error);
    }
}

// viz utils

const defaultPalette = palette.heatPalette;

export function computeLimits(data={}, targetPath) {
    const values = data?.features?.map(f => get(f?.properties, targetPath, [0]));
    const min = Math.min(...values);
    const max = Math.max(...values);
    return { min, max };
}

export function fitToRange(value, min=0, max=1, range=[0, defaultPalette.length]) {
    const x = ((range[0] * (max - value)) + (range[1] * (value - min))) / (max - min);
    return x;
}

export function getColor(value, min=0, max=1, paletteId="heatPalette", reverse=false) {
    let currentPalette = palette?.[paletteId] || defaultPalette;
    if (value === null) return "#000";
    const range = [0, currentPalette.length - 1];
    const bins = currentPalette.length;
    const step = (max - min) / bins;
    let x = Math.floor(value / step);
    if (x < range[0]) x = range[0];
    else if (x > range[1]) x = range[1];

    if (reverse) currentPalette = [ ...currentPalette ].reverse();
    return currentPalette?.[x];
}

// notif utils

export const notificationMatrix = {
    high: "#F03838",
    medium: "#F39A13",
    low: "#F3CF13",
    general: "#36A4FF",
    success: "#32CA32",
    unknown: "#C4C4C4",
    error: "#F03838",
    failure: "#F03838",
};

// file utils

const b = 1;
const kb = 1024 * b;
const mb = 1024 * kb;
const gb = 1024 * mb;

export function formatSizeBytes(size) {
    if (size > gb) return `${(size / gb).toFixed(1)} gb`;
    else if (size > mb) return `${(size / mb).toFixed(1)} mb`;
    else if (size > kb) return `${(size / kb).toFixed(1)} kb`;
    else if (size > b) return `${(size / b).toFixed(1)} b`;
    return size;
}

// query utils 

export function convertFilterToMongoQuery(filterValue, type) {
    let mongoFilter = null;
    try {
        switch (type) {
            case "range":
                mongoFilter = {
                    $lte: filterValue?.[1],
                    $gte: filterValue?.[0]
                };
                break;
            case "categorical":
                mongoFilter = {
                    $in: Object.keys(filterValue).filter(key => filterValue[key])
                };
                break;
            default:
                break;
        }
    } catch (error) {
        console.log(error);
    }
    return mongoFilter;
}

export function searchObjects(query, objectArray=[], keys=[], filterExp=null) {
    try {
        if (!query) return objectArray;
        const searchQuery = new RegExp(query, "gi");
        const filteredObjects = objectArray.map(obj => {
            const token = JSON.stringify(pick(obj, keys));
            const count = (token.match(searchQuery) || []).length;
            return { obj, count };
        });
        const result = 
            sortBy(filteredObjects, "count")
            .filter(d => d.count > 0)
            .map(d => d.obj)
            .reverse();
        if (filterExp) return filter(result, filterExp);
        return result;
    } catch (error) {
        console.log(error)
        return [];
    }
}

export function decodePayload(token) {
    const payload = token.split(".")[1];
    let data = null;
    let exp = null;
    let iat = null;
    try {
        if (payload) {
            ({ data, exp, iat } = JSON.parse(Buffer.from(payload, "base64")));
        }
    } catch (error) {
        console.error("Invalid token received");
    }
    return { data, exp, iat };
}