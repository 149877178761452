import React from 'react';
import uuid from 'react-uuid';
import { NavLink } from 'react-router-dom';

const TabMenu = ({ routes }) => {
    return ( 
        <div className="tab-menu">
            {routes?.map((route, i) => (
                <NavLink 
                key={uuid()} 
                to={route?.route}
                activeStyle={{
                    color: "#eb9812"
                }}>{route?.name}</NavLink>
            ))}
        </div>
    );
}
 
export default TabMenu;