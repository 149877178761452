import { get } from 'lodash';
import React, { useState } from 'react';
import { FlexibleWidthXYPlot, HeatmapSeries, Hint, XAxis, YAxis } from 'react-vis';
import { autoFormatDate, days, formatDurationMS, months } from '../../utils';
import Select from '../form/Select';
import CustomHint from './CustomHint';

const shortMonths = months.map(m => m.slice(0, 3));
const shortDays = days.map(m => m.slice(0, 3));

const dayConversions = (year) => {
    let prev = 0;
    const marks = new Array(12).fill(0).map((v, i) => {
        const d = new Date(year, i + 1, 0).getDate();
        const next = prev + d;
        const r = Math.floor((next + 1) / 7);
        prev = next;
        return r;
    });
    marks.unshift(0);
    marks.pop();
    return marks;
}

const axisProps = {
    tickSize: 0,
    style: {
        text: {
            stroke: "none",
            fill: "rgb(180, 180, 180)"
        },
        ticks: {
            stroke: "rgba(0, 0, 0, 0)"
        }
    }
};

const dayLength = 24 * 60 * 60 * 1000;

function buildGraph(year) {
    const plotData = [];
    const startDate = new Date(year, 0, 0);
    const endDate = new Date(year + 1, 0, 0);
    const startDay = startDate.getDay();
    const endDay = endDate.getDay();
    const dayDelta = ((endDate.getTime() - startDate.getTime()) / dayLength) + startDay + (endDay > 0 ? 7 - endDay : 0);
    const n = shortDays.length;
    const m = Math.ceil(dayDelta / n);
    const newStartDate = new Date(startDate.getTime() - (startDay * dayLength)).getTime();
    const newEndDate = new Date(endDate.getTime() + ((7 - endDay) * dayLength)).getTime();
    for (let i = 0; i < m * n; i += 1) {
        const t = new Date(newStartDate + (i * dayLength));
        const x = Math.floor(i / n);
        const y = 6 - (i % n);
        plotData.push({
            x, y, t, 
            value: 0.5,
            color: "rgb(30, 30, 30)",
        })
    }
    return { plotData, m, n, startDate: newStartDate, endDate: newEndDate };
}

const side = 14;
const padding = 3;

const GitGraph = ({ years=[new Date().getFullYear()], year=new Date().getFullYear(), data=[] }) => {
    const [hintValue, setHintValue] = useState(null);
    const [currentYear, setCurrentYear] = useState(year);
    const marks = dayConversions(currentYear);
    const { plotData, m, n, startDate, endDate } = buildGraph(currentYear);
    if (data.length) {
        plotData.push(...data.filter(d => d.t >= startDate && d.t <= endDate));
    }
    
    const onValueMouseOver = (datapoint, event) => {
        if (datapoint.x !== hintValue?.x && datapoint.y !== hintValue?.y) {
            const value = get(datapoint, "rawValue", 0);
            setHintValue({
                ...datapoint,
                text: value ? formatDurationMS(value) : `No activity`
            });
        }
    };

    const onValueMouseOut = () => {
        setHintValue(null);
    };

    return ( 
        <div className="git-graph-container">
            <div className="git-graph no-scroll">
                <FlexibleWidthXYPlot
                className="git-graph-inner"
                height={n * (side + padding) + 40}
                width={(m - 1) * (side + padding) + 35}
                xDomain={[0, m]}
                yDomain={[0, n]}
                margin={{
                    left: 35,
                    top: 30,
                    right: 0,
                    bottom: 10
                }}
                onMouseLeave={onValueMouseOut}>
                    <HeatmapSeries 
                    data={plotData}
                    colorType={"literal"}
                    color="rgb(17, 189, 17)"
                    style={{
                        strokeWidth: "2px",
                        stroke: "rgba(40, 40, 40, 0.2)",
                        width: side + "px",
                        height: side + "px",
                        rectStyle: {
                            cursor: "pointer",
                            rx: "3px",
                            ry: "3px"
                        }
                    }}
                    getOpacity={d => d.value}
                    onValueMouseOver={onValueMouseOver}
                    onValueMouseOut={onValueMouseOut} />
                    <XAxis orientation="top" hideLine tickTotal={m} {...axisProps} tickSize={-5} tickFormat={(v) => {
                        const idx = marks.indexOf(v - 2);
                        if (idx !== -1) {
                            return shortMonths?.[idx];
                        }
                        return null;
                    }} />
                    <YAxis hideLine {...axisProps} tickSize={3} tickFormat={(v, i) => (i + 1) % 2 === 0 ? shortDays?.[6 - v] : null}  />
                    {hintValue ? (
                        <Hint value={hintValue}>
                            <CustomHint 
                            datapoint={hintValue} 
                            className="git-hint"
                            getX={d => autoFormatDate(d.t, false, { date: true, time: false })}
                            getY={d => d.text} />
                        </Hint>
                    ) : (null)}
                </FlexibleWidthXYPlot>
            </div>
            <Select menuItems={years} currentItem={currentYear} setCurrentItem={setCurrentYear} />
        </div>
    );
}
 
export default GitGraph;