const stationMapSettingsDefault = {
    "stop signs": true,
    "attendants": true,
    "bay numbers": true,
    "vehicles": true,
    "free vehicles": true
};

const settings = {
    "schematic": stationMapSettingsDefault
};

export default settings;