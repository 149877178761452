import React, { useEffect } from 'react';
import mailIcon from '../graphics/mail.svg';
import flagIcon from '../graphics/india.png';
import { useLocation } from 'react-router';
import { useState } from 'react';

const footerRoutes = [
    "/",
    "/login",
    "/signup"
];

const Footer = () => {
    const location = useLocation();
    const [showFooter, toggleFooter] = useState(true);
    useEffect(() => {
        if (footerRoutes.includes(location.pathname)) toggleFooter(true);
        else toggleFooter(false);
    }, [location]);

    if (!showFooter) return null;
    return ( 
        <footer>
            <div className="copyright">© Nawgati 2021</div>
            <div className="motto">
                <p>Made with Intent in </p>
                <img src={flagIcon} alt="India" className="icon" />
            </div>
            <div className="social">
                <a href="mailto:team@nawgati.com">
                    <img src={mailIcon} alt="mail" />
                </a>
            </div>
        </footer>
    );
}
 
export default Footer;