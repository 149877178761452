import React from 'react';
import warningIcon from '../graphics/warning.svg';
import errorIcon from '../graphics/error.svg';

const errors = {
    warning: {
        icon: warningIcon
    },
    error: {
        icon: errorIcon
    }
};

const Error = ({ text, type="error" }) => {
    return ( 
        <div className="error-container">
            <div className="error-frame">
                <img src={errors[type].icon} alt={type} className="icon" />
                <p>{text}</p>
            </div>
        </div>
    );
};
 
export default Error;